@use 'variables';
@use 'mixins';

.contact{
    margin-top: 20px;
    padding: 20px;
    background-color: variables.$primaryColor;
}

.contact__grid{
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    img{
        width: 200px;
        border-radius: 50%;
    }

    a{
        margin-left: auto;
        margin-right: auto;
    }

    div{
        margin: 10px 0px;
        @include mixins.centrate();
    }
}

.whatsapp{
    height: 70px;
    transition: 0.5s;

    &:hover{
        transform: scale(1.1);
    }

    &__icon{
        font-size: 5em;
        color: #25D366;
    }
}

.mail{
    margin-top: 10px;
}

