.experience__cards-container {
  margin: 10px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.experience-card {
  height: 80px;

  @media (max-width: 410px) {
    margin: 0px 10px;
    max-width: 100%;
  }

  img {
    width: 100%;
    height: 230px;
    object-fit: cover;
  }
}
