@use 'variables';

.phone-btn{

  a {
    width: 170px;
    display: flex;
    align-items: center;
    margin-bottom: auto;
    margin-top: auto;
    gap: 10px;
    height: 30px;
    padding: 20px;
    background-color: variables.$secondaryColor;
    cursor: pointer;
    box-shadow: 2px 2px 0px #00000040;
    text-decoration: none;
    color: white;
    margin-top: auto;
    transition: 0.5s;
    
    &:hover {
      transform: scale(1.03);    
    }
    
    .icon {
      font-size: 1.2em;
    }
  }
}