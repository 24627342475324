@use 'mixins';

.services {
  @include mixins.centrate();

  .text {
    width: 80%;
    text-align: justify;
    @include mixins.centrate();

    @media (max-width: 410px) {
      width: 90%;
    }
  }

  p {
    max-width: 800px;
  }

  &__images {
    display: flex;
    padding: 20px;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.service-card {
  width: 250px;

  @media (max-width: 410px) {
    width: 100%;
  }

  img {
    width: 100%;
    height: 230px;
    object-fit: cover;
  }
}
