@use 'mixins';

.main {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  padding: 20px;
  flex-wrap: wrap;
  gap: 20px;

  .left {
    width: 500px;

    .text-box {
      @include mixins.centrate();
      margin-bottom: 20px;

      font-family: 'Sofia Sans', sans-serif;

      .text {
        text-align: justify;
      }

      .signature {
        margin-top: 10px;
        width: 100%;
        text-align: right;
        font-family: 'Vibur', cursive;
      }
    }
  }

  .right {
    img {
      width: 100%;
      max-width: 500px;
      border-radius: 3px;
    }
  }
}

.doctoralia{
 width: fit-content;
}

.review{
  margin-top: -10px;
  text-align: center;
  border-radius: 2px;
  box-shadow: 2px 2px 0px #00000040;
  text-decoration: none;
  padding: 10px;
  background-color: #00C3A5;
  color: white;
  margin-bottom: 20px;
  width: 298px;
  transition: 0.5s;


  &:hover{
    transform: scale(1.03);
  }
}

