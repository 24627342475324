@use 'variables';

.header {
  display: flex;
  justify-content: space-between;
  background-color: variables.$primaryColor;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 150px;

  @media (max-width: 910px) {
    justify-content: center;
    padding: 10px 30px;
    gap: 10px;
    padding-bottom: 20px;
  }

  img {
    height: 100px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  .linkedin {
    margin-top: 4px;
    height: 30px;
    width: 30px;
    color: #0077b5;
    transition: 0.5s;

  &:hover {
    transform: scale(1.1);
  }
  }

  .header-whatsapp{
    margin-top: 4px;
    height: 30px;
    width: 30px;
    color: #25D366;
    transition: 0.5s;


  &:hover {
    transform: scale(1.1);
  }
  }
}
